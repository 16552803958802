<template>
    <searchBody>
        <search class="searchbar" showExport :label-width="80" :searchs="searchs" @search="onSearch" @reset="reset" @export="onExport"></search>
        <Card class="table_container" ref="table_container">
            <Table stripe class="table mt_10" :columns="dataColumn" :data="dataList">
                <template slot-scope="{ row }" slot="third_order">
                    {{ row.third_order || '' }}
                </template>
                <template slot-scope="{ row }" slot="status">
                    <span :class="'now_state_' + row.status">{{ { 0: '进行中', 1: '已完成' }[row.status] }}</span>
                </template>
                <template slot-scope="{ row }" slot="assignStatus">
                    <span :class="'now_state_' + getAssignStatus(row)">{{ getAssignStr(row) }}</span>
                </template>
                <template slot-scope="{ row }" slot="manager">
                    <div class="table_manager flex flex_wrap align_center">
                        <Button v-btn="'ORDER_LIST_DELETE'" type="error" size="small" ghost @click="onDelete(row)">删除</Button>
                        <Button v-btn="'ORDER_LIST_QUERY'" type="primary" size="small" ghost @click="onEdit(row.id)">查看</Button>
                    </div>
                </template>
            </Table>
        </Card>
        <div class="relative width_100_percent text_align_right">
            <Page size="small" :total="totalPageNum || 1" :page-size="1" show-elevator @on-change="currentChange" />
        </div>
        <mySpin :loading="ajaxLoading"></mySpin>
    </searchBody>
</template>
<script>
import pageMixins from '@/lib/pageMixins';
import tableSearch from '@/components/tableSearch';
import searchBody from '@/components/searchBody';
import { reqDelReservation, reqReservationList } from '@/lib/request/auth';
import { consoleJson } from '@/lib/util';
import { havePermission } from '@/router/routes';

export default {
    name: 'orderManagerList',
    mixins: [pageMixins],
    components: {
        search: tableSearch,
        searchBody,
    },
    data() {
        return {
            searchs: [
                { id: hm.createId(), label: '编号', placeholder: '输入编号', type: 'input', bind: 'id' },
                { id: hm.createId(), label: '城市选择', placeholder: '请选择', type: 'city', bind: 'city' },
                { id: hm.createId(), label: '预约单号', placeholder: '输入预约单号', type: 'input', bind: 'order_no' },
                {
                    id: hm.createId(),
                    label: '状态',
                    placeholder: '选择状态',
                    type: 'select',
                    bind: 'status',
                    list: [
                        { id: '1', title: '已完成' },
                        { id: '0', title: '进行中' },
                    ],
                },
            ],
            dataColumn: [
                { key: 'id', title: '编号', minWidth: 80 },
                { key: 'sid', title: '服务ID', minWidth: 80 },
                { key: 'username', title: '预约人', minWidth: 130 },
                { key: 'mobile', title: '预约电话', minWidth: 120 },
                { slot: 'third_order', title: '灯光检测订单号', minWidth: 150 },
                { key: 'order_no', title: '预约单号', minWidth: 120, tooltip: true },
                { slot: 'status', title: '状态', minWidth: 100 },
                { slot: 'assignStatus', title: '是否分配', minWidth: 100 },
                {
                    key: 'createtime',
                    title: '预约时间',
                    minWidth: 120,
                    render(h, data) {
                        let { stime, etime } = data.row || {};
                        // if (stime) {
                        //     stime = new Date(stime * 1000).Format('yyyy-MM-dd HH:mm:ss') + '';
                        // }
                        // if (etime) {
                        //     etime = new Date(etime * 1000).Format('yyyy-MM-dd HH:mm:ss') + '';
                        // }
                        return h('div', stime);
                    },
                },
                { slot: 'manager', title: '操作', minWidth: 120 },
            ],
            dataList: [],
        };
    },
    mounted() {
        this.getList();
    },
    methods: {
        isServiceProvider() {
            return havePermission('ASSIGN_EMPLOYEE');
        },
        getAssignStatus(row) {
            let checkid = row.checkid || 0;
            if (this.isServiceProvider()) {
                if (checkid != 0) {
                    return 1;
                } else {
                    return 0;
                }
            } else {
                let mid = row.mid || 0;
                if (mid != 0) {
                    return 1;
                } else {
                    return 0;
                }
            }
        },
        getAssignStr(row) {
            let checkid = row.checkid || 0;
            if (this.isServiceProvider()) {
                //服务商登入
                if (checkid != 0) {
                    return '已分配给:' + checkid;
                } else {
                    return '待分配检测人员';
                }
            } else {
                let mid = row.mid || 0;
                let strs = [];
                if (mid != 0) {
                    strs.push(`[${mid}]`);
                }
                if (checkid != 0) {
                    strs.push(`[${checkid}]`);
                }
                if (strs.length <= 0) {
                    return '待分配服务商';
                } else {
                    return '已分配给:' + strs.join('-');
                }
            }
        },
        //查询列表数据
        getList() {
            consoleJson(this.params);
            
            this.showAjaxLoading();
            reqReservationList(this.params)
                .then(res => {
                    this.dataInjectDataList(res);
                })
                .finally(() => {
                    this.hideAjaxLoading();
                });
        },
        //编辑
        onEdit(id) {
            this.goUrl('/orderDetail', {
                id,
            });
        },
        //删除
        onDelete(info) {
            let { id, order_no } = info || {};
            this.confirmDialog(`确认删除【${order_no}】吗`).then(isOK => {
                if (isOK == 1) {
                    this.showAjaxLoading();
                    reqDelReservation({ id })
                        .then(res => {
                            this.fadeAlert(res.errmsg);
                            this.getList();
                        })
                        .catch(() => {
                            this.hideAjaxLoading();
                        });
                }
            });
        },
      
    },
};
</script>
